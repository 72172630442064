<template>
  <master-layout>

    <ion-fab
      vertical="top"
      horizontal="start"
      slot="fixed"
      class="closebutton"
      @click="saveRating('close')"
    >
      <ion-fab-button color="light">
        <ion-icon name="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <div class="top">
      <h1>Köszönjük az értékelésed!</h1>
      <h1 class="stars"
        v-if="myRating != null"
      >
        <span
          v-for="i in 5"
          :key='i'
        >
          <ion-icon
            v-if="myRating.Stars >= i"
            name="star"
            color="primary"
          />
          <ion-icon
            v-else
            name="star-outline"
            color="primary"
          />
        </span>
      </h1>
    </div>

    <p>
      Segítsd véleményeddel a közösséget, meséld el bővebben a borélményed itt<span v-if="winery">: <b>{{ winery.Name}}</b></span>
    </p>

    <ion-card
      v-if="myRating != null"
    >
      <ion-item lines="none">
        <ion-textarea
          rows="5"
          placeholder="Borélményem"
          v-model="myRating.Comment"
        >
        </ion-textarea>
      </ion-item>
    </ion-card>

    <div
      v-if="myRating != null"
      class="button-container"
    >
      <ion-button
        v-if="!myRating.Comment"
        disabled
        shape="round"
        expand="block"
      >
        Küldés
      </ion-button>
      <ion-button
        v-else
        shape="round"
        expand="block"
        @click="saveRating('send')"
      >
        Küldés
      </ion-button>
    </div>

  </master-layout>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import {
  IonButton, IonFab, IonFabButton, IonIcon, IonCard, IonItem, IonTextarea, loadingController
} from '@ionic/vue'
import { addIcons } from 'ionicons'
import {
  close, star, starOutline
} from 'ionicons/icons'

export default {
  name: 'RatingComments',
  components: {
    IonButton, IonFab, IonFabButton, IonIcon, IonCard, IonItem, IonTextarea
  },
  data () {
    return {
      winery: null,
      myRating: null
    }
  },
  computed: {
    ...mapGetters('auth', {
      authData: 'getAuthData'
    }),
    ...mapGetters('wineries', {
      wineryInMemory: 'getWineryInMemory',
      ratingInMemory: 'getRatingInMemory'
    })
  },
  created () {
    addIcons({
      'close': close,
      'star': star,
      'star-outline': starOutline
    })
  },
  ionViewWillEnter () {
    if (this.ratingInMemory) {
      this.myRating = this.ratingInMemory
    }
    this.winery = this.wineryInMemory
  },
  methods: {
    ...mapActions('wineries', {
      setMyWineriesChanged: 'setMyWineriesChanged',
      setMyWineriesZero: 'setMyWineriesZero',
      setWineryInMemory: 'setWineryInMemory',
      setRatingInMemory: 'setRatingInMemory'
    }),

    async saveRating (i) {
      this.presentLoading()
      await axios.post(process.env.VUE_APP_STRAPI_URI + 'ratings',
        {
          User: this.authData.userId,
          Winery: this.myRating.Winery,
          Stars: this.myRating.Stars,
          Comment: this.myRating.Comment,
          Checkin: this.winery.checkinId
        },{
          headers: {
            Authorization:
            'Bearer ' + this.authData.token
          }
        }
      )
      if (i == 'send') {
        this.setRatingInMemory({
          User: this.authData.userId,
          Winery: this.myRating.Winery,
          Stars: this.myRating.Stars,
          Comment: this.myRating.Comment
        })
      }
      this.setWineryInMemory(null)
      this.winery = null
      this.myRating = null
      await this.loading.dismiss()
      this.$router.push('/app/rating-thanks')
    },

    async presentLoading() {
      this.loading = await loadingController
        .create({
          spinner: 'lines'
        })
      await this.loading.present()
    }
  }
}
</script>

<style scoped>
h1{
  font-size: 20px;
}
.stars{
  font-size: 40px;
}
.closebutton{
  margin-top: calc(10px + env(safe-area-inset-top));
}
.button-container{
  width: 100%;
  bottom: calc(30px + env(safe-area-inset-bottom));
  margin-top: 30px;
}
ion-button{
  margin: 0 20px;
}
.top{
  border-bottom: 1px solid #cccccc;
  padding-top: calc(90px + env(safe-area-inset-top));
}
ion-card{
  margin: 20px 20px 0 20px;
}
p{
  margin: 25px 20px 0 20px;
  line-height: 1.5;
}
</style>
